<template>
  <!-- <div class="message-box" v-show="isShow"> -->
  <van-dialog
    v-model="isShow"
    :showConfirmButton="false"
    :showCancelButton="false"
    className="dialog-box"
  >
    <transition name="fade">
      <div class="inner" v-show="isShow">
        <component
          :is="isModel"
          :amountMoney="amountMoney"
          :details="details"
          @hide="hide"
        >
        </component>
      </div>
    </transition>
    <div class="close">
      <div class="close-btc" @click="hide">
        <img src="@/assets/close.png" />
      </div>
    </div>
  </van-dialog>

  <!-- </div> -->
</template>
<script>
import Module from "./index";
export default {
  props: {
    moduleName: {
      type: String,
      default: "Redem",
    },
    isShow: {
      type: Boolean,
    },
    amountMoney: {
      type: String,
      default: "0",
    },
    details: {
      type: Object,
    },
  },
  data() {
    return {
      isModel: null,
    };
  },
  created() {
    this.isModel = Module[this.moduleName];
  },
  methods: {
    hide() {
      this.$emit("onIsShow");
    },
  },
};
</script>
<style lang="scss" scoped>
body {
  margin: 0;
}

h1 {
  margin: 0;
  font-weight: normal;
}

.van-dialog {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  // background: #171717;
}
// .message-box {
//   //粘性定位实现遮罩层
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   z-index: 1; //element里面默认是2000 我大他一点 如果层级不够跟其他组件出现冲突 可以配置成prop参数传入手动配置
//   background-color: rgba(0, 0, 0, 0.8);

  .inner {
    position: absolute;
    top: 100px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    background-color: #171818;
    box-shadow: 1px 3px 5px #171818;
    overflow: hidden;
    border-radius: 5px;
  }
  /* //淡入淡出 */
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .close {
    width: 100%;
    position: absolute;
    bottom: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  //弹框提供四种提示类型 对应四种样式
  // &.primary {
  //   header {
  //     background-color: rgb(148, 202, 231);
  //     color: #fff;
  //   }
  // }

  // &.success {
  //   header {
  //     background-color: rgb(148, 231, 173);
  //     color: #fff;
  //   }
  // }

  // &.warn {
  //   header {
  //     background-color: rgb(245, 175, 122);
  //     color: #fff;
  //   }
  // }

  // &.danger {
  //   header {
  //     background-color: rgb(240, 145, 145);
  //     color: #fff;
  //   }
  // }
// }
</style>
