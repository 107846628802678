const en_IN = {
  homeDiv: {
    home_title: "एयरड्रॉप प्राप्त करने के लिए स्नैपशॉट",
    home_snapshot_coin: "स्नैपशॉट कॉइन",
    home_add_wallet: "वॉलेट में जोड़ें",
    home_capital: "पूंजी",
    home_participate: "भाग लें",
    home_earnings: "कमाई",
    home_withdraw: "निकालें",
    home_aggregate_Earnings_Pool: "कुल कमाई पूल",
    home_you_send: "आप भेजते हैं",
    home_you_get: "आप प्राप्त करते हैं",
    home_buy_vANY: "vANY खरीदें",
  },
  homeJS: {
    home_snapshot: "स्नैपशॉट",
    home_incentives: "प्रोत्साहन",
    home_all: "सभी",
    home_toward: "की ओर",
    home_stop: "रोकें",
    home_my: "मेरा",
    home_status: "स्थिति",
    home_token_added: "टोकन को वॉलेट में जोड़ दिया गया है।",
    home_token_addition: "टोकन जोड़ने में विफल।",
    home_error_adding: "वॉलेट में टोकन जोड़ने में त्रुटि।",
    home_error_adding_tokenYour:
      "टोकन जोड़ने में त्रुटि। आपका वॉलेट इसे सपोर्ट नहीं कर सकता है",
    home_please_install: "कृपया वॉलेट इंस्टॉल करें",
    home_please_switch_networks: "कृपया नेटवर्क बदलें",
    home_network_error: "नेटवर्क त्रुटि, नेटवर्क बदलें और पुनः प्रयास करें",
    home_network_errorthis: 'नेटवर्क गलत है। सही नेटवर्क आईडी है:',
    home_network_current: 'वर्तमान नेटवर्क है:',
    home_stake_loading: "स्टेक लोड हो रहा है",
    home_snapshot_balance:
      "इनाम प्राप्त करने के लिए स्नैपशॉट बैलेंस पर्याप्त नहीं है, कम से कम:",
    home_you_are_blocked: "आप अवरुद्ध हैं",
    home_loading: "लोड हो रहा है...",
    home_account_error: "खाता त्रुटि",
    home_please_switch_chains: "कृपया श्रृंखला बदलें",
    home_please_network: "कृपया सही नेटवर्क का चयन करें",
    home_please_address: "कृपया अपना पता कनेक्ट करें",
    home_wallet_connect_deny: "वॉलेट कनेक्ट अस्वीकृत",
    home_old_wallet_version: "पुराना वॉलेट संस्करण",
    home_switch_chain_and_retry: "श्रृंखला बदलें और पुनः प्रयास करें",
    home_enter_number_tokens: "टोकन की संख्या दर्ज करें",
    home_please_select_token: "कृपया एक टोकन चुनें",
    home_copy_success: "कॉपी सफल",
    home_copy_error: "कॉपी त्रुटि",
  },
  rulesDiv: {
    rules_title: "नियम और ब्याज आवंटन",
    rules_text:
      "अपने दोस्तों को आमंत्रित करके, यदि आपके दोस्त खनन में पैसा डालते हैं, तो आपको अपने दोस्तों के ब्याज से 10-30 प्रतिशत का इनाम मिलेगा, और यह ब्याज प्रभावित नहीं करेगा",
    rules_text2:
      "सिक्कों को गिरवी रखने और ब्याज प्राप्त करने के लिए पैसे का निवेश करें, यदि परिवर्तन एक निश्चित स्तर तक गिरता है, तो प्लेटफ़ॉर्म टोकन को परिसमाप्त कर देगा",
    rules_invite_friends: "दोस्तों को आमंत्रित करें",
    rules_me: "मुझे",
    rules_layer_users: "लेयर 1 उपयोगकर्ता",
    rules_layer_users2: "लेयर 2 उपयोगकर्ता",
    rules_layer_users3: "लेयर 3 उपयोगकर्ता",
  },
  navMuenDiv: {
    nav_home: "मुख्य पृष्ठ",
    nav_rules: "नियम",
    nav_language: "भाषा",
  },
  cardDiv: {
    card_compound: "कंपाउंड V3",
    card_end_time: "समाप्ति समय",
    card_APD: "APD",
    card_interest: "ब्याज",
    card_commissions: "कमीशन",
    card_capital: "संचित पूंजी",
    card_friends_interest: "दोस्तों का ब्याज",
    card_supply: "आपूर्ति",
    card_Resupply: "Resupply",
    card_redem: "रिडीम",
    card_wiuhdraw: "निकालें",
    card_enter_amount: "राशि दर्ज करें",
    card_confirm: "पुष्टि करें",
    card_duration: "अवधि",
    card_days: "दिन",
    card_minimum_withdrawal_amount: "न्यूनतम वापसी राशि",
    card_min_prarticion_amount: "न्यूनतम भागीदारी राशि",
    card_current_Redeemable_Capital: "वर्तमान रिम्बार्सिबल कैपिटल",
    card_Withdrawn_Commission: "वापसी की कमीशन",
    card_Withdrawn_Interest: "वापसी का ब्याज",
    card_Withdrawn_Principa: "वापसी का प्रिंसिपल",
  },
  cardJS: {
    card_loading: "लोड हो रहा है",
    card_input_empty: "दर्ज की गई राशि खाली नहीं हो सकती",
    card_success: "सफलता",
  },
  modeDiv: {
    mode_title: "दोस्तों को आमंत्रित करें",
    mode_save: "सहेजें",
    mode_copy_success: "कॉपी सफल",
    mode_copy_error: "कॉपी त्रुटि",
    mode_redem: "रिडीम",
    mode_confirm: "पुष्टि करें",
    mode_input_empty: "दर्ज की गई राशि खाली नहीं हो सकती",
    mode_loading: "लोड हो रहा है...",
    mode_success: "सफलता",
    mode_failed: "असफल",
    mode_insufficient: "प्राकृतिक गैस के लिए अपर्याप्त धन",
    mode_transaction: "लेन-देन असफल रहा",
    mode_insufficient_balance: "अपर्याप्त शेष राशि",
    mode_redeem_failed: "रिडीम असफल रहा",
    mode_rules: "नियम",
    mode_inviting:
      "अपने दोस्तों को आमंत्रित करके, यदि आपके दोस्त खनन में पैसा डालते हैं, तो आपको 10 प्रतिशत का इनाम मिलेगा",
    mode_supply: "आपूर्ति",
    mode_user_denied: "उपयोगकर्ता ने लेन-देन को अस्वीकार कर दिया",
    mode_wiuhdraw: "निकालें",
    mode_: "दोस्तों को आमंत्रित करें",
    mode_wiuhdraw_text: 'अपनी ब्याज या आयोग को वापस ले लें',
    mode_supply_text: "अपना प्रमुख दो",
    mode_redem_text: "अपना प्रमुख वापसी करें",
    mode_rules_text_1: "अपने दोस्तों को आमंत्रित करके, अगर आपके दोस्त माइनिंग में पैसा लगाते हैं, तो आपको 10-30 प्रतिशत ब्याज मिलेगा।",
    mode_rules_text_2: "एपीडी फंड्स पर दैनिक रिटर्न है, जो फंड के उपयोग द्वारा निर्धारित होगा और थोड़े से फ्लक्चुएट करेगा।",
    mode_rules_text_3: "आपका ब्याज या कमीशन, न्यूनतम निकासी राशि पर पहुंचने पर किसी भी समय वापस लिया जा सकता है।",
    mode_rules_text_4: "आप देते हैं फंड्स, अवधि पूरी करने के बाद, वर्तमान में रिडीमेबल पूंजी में दिखाई जा सकते हैं, और आप रिडीम कर सकते हैं।",
    mode_rules_text_5: "प्रोजेक्ट का अंत का मतलब है: वर्तमान प्रोजेक्ट की अंतिम तारीख, प्रोजेक्ट का अंत आपके निधि या ब्याज, कमीशन निकालने पर कोई प्रभाव नहीं डालता है, प्रोजेक्ट का अंत, एक नया प्रोजेक्ट रिलीज होगा, आप एक नए प्रोजेक्ट में फिर से शामिल हो सकते हैं।",
    
  },
  swapDiv: {
    swap_title: "विनिमय",
    swap_trade: "तुरंत टोकन व्यापार",
    swap_balance: "शेष",
    swap_price: "मूल्य",
    swap_slippage: "स्लिपेज टॉलरेंस",
    swap_point: "स्लाइडिंग बिंदु का चयन करें",
    swap_SWAP: "विनिमय",
  },
  swapJS: {
    swap_quantity: "कृपया सही मात्रा दर्ज करें",
    swap_Successfullyswap: "सफलतापूर्वक स्वैप करें।",
    swap_failed: "स्वैप विफल हुआ।",
    swap_input_amount: "इनपुट राशि रिक्त नहीं हो सकती।",
    swap_select_token: "कृपया एक टोकन का चयन करें।",
    swap_insufficient_balance: "आपका बैलेंस अपर्याप्त है।",
  },
};
export default en_IN;
