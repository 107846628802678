import Vue from "vue";

import {
  test,
  formatNumber,
  ConnectWallet,
  ConnectWbe3,
  onCheckChain,
  timestampToTime,
  toFixedTwo,
  toFixedFloat,
} from "./comom/wallet";
Vue.mixin({
  methods: {
    test,
    formatNumber,
    ConnectWallet,
    ConnectWbe3,
    timestampToTime,
    onCheckChain,
    toFixedTwo,
    toFixedFloat,
  },
});
