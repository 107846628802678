<template>
  <div class="wzc_select" :style="styleVar">
    <div class="divSelect" :class="{ drop_down: isListShow }" ref="divSelect">
      <div class="divSelectinput" @click="dropDownSelect">
        <!-- 选中后的内容 -->
        <div
          class="selectinfos"
          :title="label"
          :class="{ no_select: label == 'Please select' }"
        >
          {{ label }}
        </div>
        <!-- 三角形图标 -->
        <!-- <i class="imgthree fa fa-caret-up" :class="{ 'is-reverse': isListShow }"></i> -->
        <div
          class="imgthree fa fa-caret-up"
          :class="{ 'is-reverse': isListShow }"
        >
          <img src="@/assets/icon.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 下拉框列表 -->
    <transition name="drop-down">
      <div class="Selectlist" v-show="isListShow" ref="dropDown">
        <!-- <div class="select_triangle"></div> -->
        <div class="select_triangle-black"></div>
        <ul class="wzc_option_list">
          <slot name="wzc_option"></slot>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "wzc_select",
  components: {},
  props: {
    placeholder: {
      type: String,
      default: "Please select",
    },
    width: {
      type: Number,
      default: 180,
    },
    height: {
      type: Number,
      default: 40,
    },
  },
  data() {
    return {
      label: "",
      isListShow: false,
      optionid: "",
    };
  },
  created() {
    this.label = this.placeholder;
  },
  mounted() {
    let _this = this;
    document.addEventListener("click", function (e) {
      if (_this.$refs.divSelect) {
        if (
          !!_this.$refs.divSelect.contains(e.target) ||
          !!_this.$refs.dropDown.contains(e.target)
        )
          return;
        else _this.isListShow = false;
      }
    });
  },
  computed: {
    styleVar() {
      return {
        "--select-height": this.height + "px",
        "--select-width": this.width + "px",
      };
    },
  },
  methods: {
    dropDownSelect() {
      this.isListShow = !this.isListShow;
    },
  },
};
</script>
<style lang="scss" scoped>
.wzc_select {
  background: #000000;
  border-radius: 5px;
  height: var(--select-height);
  width: var(--select-width);
  line-height: var(--select-height);
}
.divSelect {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 0.75rem;
}
.drop_down {
}
.divSelectinput {
  width: calc(100% - 20px);
  height: 100%;
  margin: 0 5px 0 15px;
  display: flex;
}
.selectinfos {
  width: 71.5%;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.no_select {
  color: #d3dce6;
}
.divSelectinput img {
  width: 12px;
  height: 12px;
}
.imgthree {
  // wzc_option_dropdown_item

  line-height: var(--select-height);
  text-align: center;
  transform: rotate(180deg);
  transition: all 0.3s;
}
.imgthree:before {
  cursor: pointer;
  color: #d3dce6;
}
.imgthree.is-reverse {
  transform: rotate(0deg);
}

.Selectlist {
  z-index: 800;
  position: relative;
  border: 1px solid #b4ef52;
  // border-top: 1px solid transparent;
  // background: #000000;
  top: -32px;
}
.wzc_option_list {
  border-radius: 5px;
  width: 100%;
  padding: 3px 0px;
  background: #000000;
  margin: 0;
}
.select_triangle-black{
  height: 30px;
}
.select_triangle {
  width: 14px;
  height: 7px;
  position: relative;
  left: 15px;
}
.select_triangle::before {
  position: absolute;
  content: "";
  left: 0px;
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 8px solid #000000;
}
.select_triangle::after {
  position: absolute;
  left: 2px;
  top: 2px;
  content: "";
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 8px solid #000000;
}
.drop-down-enter {
  opacity: 0;
  transform: translate(0px, -80px) scaleY(0.2);
}
.drop-down-leave-to {
  opacity: 0;
  transform: translate(0px, -80px) scaleY(0.2);
}
.drop-down-enter-active {
  transition: all 0.5s ease-in;
}
.drop-down-leave-active {
  transition: all 0.5s ease;
}
</style>
