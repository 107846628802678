import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Storage from "vue-ls";
import VueClipboard from 'vue-clipboard2';
import VueLuckyCanvas from '@lucky-canvas/vue'//抽奖插件

import i18n from '@/vueI18n/index'
import Vant from 'vant';
import 'vant/lib/index.css';
import "@/directive"
import 'animate.css';
import "@/mixin";
import "@/components"
// 加载 element 组件库
import ElementUI from "element-ui";
// 加载 element 组件库的样式
import "element-ui/lib/theme-chalk/index.css";
Vue.config.productionTip = false
// vue-ls 的配置
const storageOptions = {
  namespace: "vue_", // key 键的前缀（随便起）
  name: "ls", // 变量名称（随便起） 使用方式：Vue.变量名称 或 this.$变量名称
  storage: "local", // 作用范围：local、session、memory
};
Vue.use(VueLuckyCanvas)
Vue.use(Storage, storageOptions);
Vue.use(VueClipboard);
Vue.use(Vant);
// 全局注册 element 组件库
Vue.use(ElementUI);
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
