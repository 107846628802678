const en_YN = {
  homeDiv: {
    home_title: "Chụp ảnh để nhận Airdrops",
    home_snapshot_coin: "Đồng Snapshot",
    home_add_wallet: "Thêm vào ví",
    home_capital: "Vốn",
    home_participate: "Tham gia",
    home_earnings: "Thu nhập",
    home_withdraw: "Rút tiền",
    home_aggregate_Earnings_Pool: "Tổng hợp thu nhập",
    home_you_send: "Bạn gửi",
    home_you_get: "Bạn nhận",
    home_buy_vANY: "Mua vANY",
  },
  homeJS: {
    home_snapshot: "Chụp ảnh",
    home_incentives: "Khuyến khích",
    home_all: "Tất cả",
    home_toward: "Hướng tới",
    home_stop: "Dừng lại",
    home_my: "Của tôi",
    home_status: "trạng thái",
    home_token_added: 'Token đã được thêm vào ví.',
    home_token_addition: 'Việc thêm token thất bại.',
    home_error_adding: 'Lỗi khi thêm token vào ví.',
    home_error_adding_tokenYour:
      "Lỗi thêm token. Ví của bạn có thể không hỗ trợ nó",
    home_please_install: "Vui lòng cài đặt ví",
    home_please_switch_networks: "Vui lòng chuyển mạng",
    home_network_error: "Lỗi mạng, chuyển mạng và thử lại",
    home_stake_loading: "Đang tải stake",
    home_snapshot_balance: "số dư snapshot không đủ để nhận thưởng, ít nhất:",
    home_you_are_blocked: "Bạn bị chặn",
    home_loading: "đang tải...",
    home_account_error: "lỗi tài khoản",
    home_please_switch_chains: "Vui lòng chuyển chuỗi",
    home_please_network: "Vui lòng chọn mạng đúng",
    home_please_address: "Vui lòng kết nối địa chỉ của bạn",
    home_wallet_connect_deny: "kết nối ví bị từ chối",
    home_old_wallet_version: "phiên bản ví cũ",
    home_switch_chain_and_retry: "Chuyển chuỗi và thử lại",
    home_enter_number_tokens: "Nhập số lượng token",
    home_please_select_token: "Vui lòng chọn một token",
    home_copy_success: "sao chép thành công",
    home_copy_error: "lỗi sao chép",
  },
  rulesDiv: {
    rules_title: "Quy tắc và phân bổ lãi suất",
    rules_text:
      "Bằng cách mời bạn bè của bạn tham gia, nếu bạn bè của bạn đầu tư tiền vào khai thác, bạn sẽ được thưởng 10 phần trăm lãi suất từ bạn bè của bạn, và lãi suất này sẽ không ảnh hưởng",
    rules_text2:
      "Đầu tư tiền để cầm cố đồng tiền và nhận lãi suất, nếu thay đổi giảm xuống mức nhất định, nền tảng sẽ thanh lý các token",
    rules_invite_friends: "Mời bạn bè",
    rules_me: "TÔI",
    rules_layer_users: "Người dùng lớp 1",
    rules_layer_users2: "Người dùng lớp 2",
    rules_layer_users3: "Người dùng lớp 3",
  },
  navMuenDiv: {
    nav_home: "Trang chủ",
    nav_rules: "Quy tắc",
    nav_language: "Ngôn ngữ",
  },
  cardDiv: {
    card_compound: "Compound V3",
    card_end_time: "Thời gian kết thúc",
    card_APD: "APD",
    card_interest: "Lãi suất",
    card_commissions: "Hoa hồng",
    card_capital: "Vốn tích lũy",
    card_friends_interest: "Lãi suất từ bạn bè",
    card_supply: "Cung cấp",
    card_Resupply:'Resupply',
    card_redem: "Chuộc lại",
    card_wiuhdraw: "Rút tiền",
    card_enter_amount: "Nhập số tiền",
    card_confirm: "xác nhận",
    card_duration: "Thời gian",
    card_days: "ngày",
    card_minimum_withdrawal_amount: "Số tiền rút tối thiểu",
    card_min_prarticion_amount: "Số tiền tham gia tối thiểu",
    card_current_Redeemable_Capital: "Vốn hiện có có thể đổi lại",
    card_Withdrawn_Commission: "Hoa hồng đã rút",
    card_Withdrawn_Interest: "Lãi suất đã rút",
    card_Withdrawn_Principa: "Gốc vốn đã rút",
  },
  cardJS: {
    card_loading: "đang tải",
    card_input_empty: "Số tiền nhập không được để trống",
    card_success: "Thành công",
  },
  modeDiv: {
    mode_title: "Mời bạn bè",
    mode_save: "Lưu",
    mode_copy_success: "sao chép thành công",
    mode_copy_error: "lỗi sao chép",
    mode_redem: "Chuộc lại",
    mode_confirm: "xác nhận",
    mode_input_empty: "Số tiền nhập không được để trống",
    mode_loading: "Đang tải...",
    mode_success: "thành công",
    mode_failed: "thất bại",
    mode_insufficient: "Không đủ kinh phí cho khí thiên nhiên",
    mode_transaction: "Giao dịch thất bại",
    mode_insufficient_balance: "Số dư không đủ",
    mode_redeem_failed: "chuộc lại thất bại",
    mode_rules: "Quy tắc",
    mode_inviting:
      "Bằng cách mời bạn bè của bạn tham gia, nếu bạn bè của bạn đầu tư tiền vào khai thác, bạn sẽ được thưởng 10-30 phần trăm lãi suất",
    mode_supply: "Cung cấp",
    mode_user_denied: "Người dùng từ chối giao dịch",
    mode_wiuhdraw: "Rút tiền",
    mode_: "Mời bạn bè",
    mode_wiuhdraw_text: 'Rút lợi nhuận hoặc hoa hồng của bạn',
    mode_supply_text: "Cung cấp vốn chủ của bạn",
    mode_redem_text: "Thưởng lại vốn chủ của bạn",
    mode_rules_text_1: "Bằng cách mời bạn bè của bạn tham gia, nếu bạn bè của bạn đầu tư tiền vào khai thác, bạn sẽ được thưởng với 10-30 phần trăm lãi suất.",
    mode_rules_text_2: "APD là lợi nhuận hàng ngày trên quỹ, sẽ dao động một cách nhẹ vì lợi nhuận sẽ được xác định bởi việc sử dụng quỹ.",
    mode_rules_text_3: "Lãi suất hoặc hoa hồng của bạn có thể rút bất cứ lúc nào khi Đạt đến Số Tiền Rút Tối Thiểu.",
    mode_rules_text_4: "Số tiền bạn cung cấp, sau khi đạt đến hạn, có thể được hiển thị trong Vốn Có Thể Đổi Hiện Tại, và bạn có thể Đổi.",
    mode_rules_text_5: "Cuối dự án có nghĩa là: thời hạn của dự án hiện tại, cuối dự án không ảnh hưởng đến quỹ hoặc lợi suất của bạn, rút hoa hồng, cuối dự án, một dự án mới sẽ được phát hành, bạn có thể tham gia lại vào một dự án mới."
  },
  swapDiv: {
    swap_title: "Trao đổi",
    swap_trade: "Giao dịch token ngay lập tức",
    swap_balance: "Số dư",
    swap_price: "Giá",
    swap_slippage: "Độ nhạy cảm với sự trượt giảm",
    swap_point: "Chọn điểm trượt giảm",
    swap_SWAP: "TRAO ĐỔI",
  },
  swapJS: {
    swap_quantity: "Vui lòng nhập số lượng chính xác",
    swap_Successfullyswap: 'Hoán đổi thành công.',
  swap_failed: 'Hoán đổi thất bại.',
  swap_input_amount: 'Số tiền nhập không thể để trống.',
  swap_select_token: 'Vui lòng chọn một token.',
  swap_insufficient_balance: 'Số dư của bạn không đủ.'
  },
};
export default en_YN;
