//泰语
const en_TH = {
  homeDiv: {
    home_title: "สแนปชอตเพื่อรับแอร์ดรอป",
    home_snapshot_coin: "สแนปชอตคอยน์",
    home_add_wallet: "เพิ่มในกระเป๋าเงิน",
    home_capital: "เงินทุน",
    home_participate: "เข้าร่วม",
    home_earnings: "รายได้",
    home_withdraw: "ถอน",
    home_aggregate_Earnings_Pool: "พูลรายได้รวม",
    home_you_send: "คุณส่ง",
    home_you_get: "คุณได้รับ",
    home_buy_vANY: "ซื้อ vANY",
  },
  homeJS: {
    home_snapshot: "สแนปชอต",
    home_incentives: "สิ่งจูงใจ",
    home_all: "ทั้งหมด",
    home_toward: "ไปทาง",
    home_stop: "หยุด",
    home_my: "ของฉัน",
    home_status: "สถานะ",
    home_token_added: "เพิ่มโทเค็นลงในกระเป๋าเงินแล้ว",
    home_token_addition: "การเพิ่มโทเค็นล้มเหลว",
    home_error_adding: "เกิดข้อผิดพลาดในการเพิ่มโทเค็นลงในกระเป๋าเงิน",
    home_error_adding_tokenYour:
      "เกิดข้อผิดพลาดในการเพิ่มโทเค็น กระเป๋าเงินของคุณอาจไม่รองรับ",
    home_please_install: "โปรดติดตั้งกระเป๋าเงิน",
    home_please_switch_networks: "โปรดเปลี่ยนเครือข่าย",
    home_network_error:
      "ข้อผิดพลาดของเครือข่าย โปรดเปลี่ยนเครือข่ายและลองใหม่อีกครั้ง",
      home_network_errorthis: 'เครือข่ายไม่ถูกต้อง หมายเลขเครือข่ายที่ถูกต้องคือ',
    home_network_current: 'เครือข่ายปัจจุบันคือ',
    home_stake_loading: "กำลังโหลดสเตค",
    home_snapshot_balance: "ยอดสแนปชอตไม่เพียงพอที่จะรับรางวัล อย่างน้อย:",
    home_you_are_blocked: "คุณถูกบล็อก",
    home_loading: "กำลังโหลด...",
    home_account_error: "ข้อผิดพลาดของบัญชี",
    home_please_switch_chains: "โปรดเปลี่ยนเชน",
    home_please_network: "โปรดเลือกเครือข่ายที่ถูกต้อง",
    home_please_address: "โปรดเชื่อมต่อที่อยู่ของคุณ",
    home_wallet_connect_deny: "การเชื่อมต่อกระเป๋าเงินถูกปฏิเสธ",
    home_old_wallet_version: "เวอร์ชันกระเป๋าเงินเก่า",
    home_switch_chain_and_retry: "เปลี่ยนเชนและลองใหม่",
    home_enter_number_tokens: "ป้อนจำนวนโทเค็น",
    home_please_select_token: "โปรดเลือกโทเค็น",
    home_copy_success: "สำเนาสำเร็จ",
    home_copy_error: "ข้อผิดพลาดในการคัดลอก",
  },
  rulesDiv: {
    rules_title: "กฎและการจัดสรรดอกเบี้ย",
    rules_text:
      "โดยการเชิญเพื่อนของคุณให้เข้าร่วม หากเพื่อนของคุณลงทุนเงินในการขุด คุณจะได้รับรางวัล 10 เปอร์เซ็นต์จากดอกเบี้ยของเพื่อนคุณ และดอกเบี้ยนี้จะไม่ส่งผลกระทบ",
    rules_text2:
      "ลงทุนเงินเพื่อค้ำประกันเหรียญและรับดอกเบี้ย หากราคาลดลงถึงระดับหนึ่ง แพลตฟอร์มจะทำการชำระบัญชีโทเค็น",
    rules_invite_friends: "เชิญเพื่อน",
    rules_me: "ฉัน",
    rules_layer_users: "ผู้ใช้ชั้น 1",
    rules_layer_users2: "ผู้ใช้ชั้น 2",
    rules_layer_users3: "ผู้ใช้ชั้น 3",
  },
  navMuenDiv: {
    nav_home: "หน้าแรก",
    nav_rules: "กฎ",
    nav_language: "ภาษา",
  },
  cardDiv: {
    card_compound: "Compound V3",
    card_end_time: "เวลาสิ้นสุด",
    card_APD: "APD",
    card_interest: "ดอกเบี้ย",
    card_commissions: "ค่าคอมมิชชัน",
    card_capital: "สะสมสินทรัพย์",
    card_friends_interest: "ดอกเบี้ยของเพื่อน",
    card_supply: "อุปทาน",
    card_Resupply: "Resupply",
    card_redem: "ไถ่ถอน",
    card_wiuhdraw: "ถอน",
    card_enter_amount: "ป้อนจำนวนเงิน",
    card_confirm: "ยืนยัน",
    card_duration: "ระยะเวลา",
    card_days: "วัน",
    card_minimum_withdrawal_amount: "จำนวนเงินถอนขั้นต่ำ",
    card_min_prarticion_amount: "จำนวนเงินเข้าร่วมขั้นต่ำ",
    card_current_Redeemable_Capital: "ทุนที่สามารถแลกเปลี่ยนได้ในปัจจุบัน",
    card_Withdrawn_Commission: "ค่าคอมมิชชั่นที่ถอนออกแล้ว",
    card_Withdrawn_Interest: "ดอกเบี้ยที่ถอนออกแล้ว",
    card_Withdrawn_Principa: "เงินต้นที่ถอนออกแล้ว",
  },
  cardJS: {
    card_loading: "กำลังโหลด",
    card_input_empty: "จำนวนเงินที่ป้อนไม่สามารถว่างได้",
    card_success: "สำเร็จ",
  },
  modeDiv: {
    mode_title: "เชิญเพื่อน",
    mode_save: "บันทึก",
    mode_copy_success: "สำเนาสำเร็จ",
    mode_copy_error: "ข้อผิดพลาดในการคัดลอก",
    mode_redem: "ไถ่ถอน",
    mode_confirm: "ยืนยัน",
    mode_input_empty: "จำนวนเงินที่ป้อนไม่สามารถว่างได้",
    mode_loading: "กำลังโหลด...",
    mode_success: "สำเร็จ",
    mode_failed: "ล้มเหลว",
    mode_insufficient: "เงินทุนไม่เพียงพอสำหรับก๊าซธรรมชาติ",
    mode_transaction: "การทำธุรกรรมล้มเหลว",
    mode_insufficient_balance: "ยอดเงินคงเหลือไม่เพียงพอ",
    mode_redeem_failed: "การไถ่ถอนล้มเหลว",
    mode_rules: "กฎ",
    mode_inviting:
      "โดยการเชิญเพื่อนของคุณให้เข้าร่วม หากเพื่อนของคุณลงทุนเงินในการขุด คุณจะได้รับรางวัล 10-30 เปอร์เซ็นต์จากดอกเบี้ย",
    mode_supply: "อุปทาน",
    mode_user_denied: "ผู้ใช้ปฏิเสธการทำธุรกรรม",
    mode_wiuhdraw: "ถอน",
    mode_: "เชิญเพื่อน",
    mode_wiuhdraw_text: 'ถอนดอกเบี้ยหรือค่าคอมมิชชั่นของคุณ',
    mode_supply_text: "ให้เงินต้นของคุณ",
    mode_redem_text: "คืนเงินต้นของคุณ",
    mode_rules_text_1: "โดยเชิญเพื่อนของคุณเข้าร่วม หากเพื่อนของคุณเพิ่มเงินเข้าไปในการทำเหมือง คุณจะได้รับดอกเบี้ย 10-30 เปอร์เซ็นต์",
    mode_rules_text_2: "APD คือ รายได้รายวันจากเงินทุน ซึ่งจะเปลี่ยนแปลงเล็กน้อยเนื่องจากรายได้จะขึ้นอยู่กับการใช้เงินทุน",
    mode_rules_text_3: "ดอกเบี้ยหรือค่าคอมมิชชั่นของคุณสามารถถอนได้ทุกเมื่อเมื่อมียอดถอนขั้นต่ำถึง",
    mode_rules_text_4: "เงินทุนที่คุณให้หลังจากมาถึงเขตเวลา สามารถแสดงในสถานะเงินทุนที่สามารถแลกได้ปัจจุบัน และคุณสามารถแลกได้",
    mode_rules_text_5: "จุดจบของโครงการหมายถึง: วันสุดท้ายของโครงการปัจจุบัน จุดจบของโครงการจะไม่มีผลต่อเงินของคุณหรือดอกเบี้ย การถอนคอมมิชชั่น จุดจบของโครงการ โครงการใหม่จะถูกปล่อย คุณสามารถเข้าร่วมโครงการใหม่ได้"

  },
  swapDiv: {
    swap_title: "สว็อป",
    swap_trade: "ซื้อขายโทเค็นทันที",
    swap_balance: "ยอดคงเหลือ",
    swap_price: "ราคา",
    swap_slippage: "ความทนทานต่อการลื่นไหล",
    swap_point: "เลือกจุดลื่น",
    swap_SWAP: "สว็อป",
  },
  swapJS: {
    swap_quantity: "โปรดใส่ปริมาณที่ถูกต้อง",
    swap_Successfullyswap: 'สลับสำเร็จแล้ว',
    swap_failed: 'การสลับล้มเหลว',
    swap_input_amount: 'ไม่สามารถเว้นช่องจำนวนที่ระบุไว้ว่างไปได้',
    swap_select_token: 'กรุณาเลือกโทเค็น',
    swap_insufficient_balance: 'ยอดเงินของคุณไม่เพียงพอ'
  },
};
export default en_TH;
