import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    interestAddress: "0xcF36C005D166FE18E61cbc3A61E4FcbB5dBb1013", //利息计算合约地址 Collaterals
    PlanAddress: "0xae061c78FcfAaa676efF15761e8F7FF0A5d85Fe1", //Plan合约地址 InvestmentPlan
    AnyAddress: "", //any合约地址 0x93d506eD35Efaa73ED94E425938B403deA77D757
    InvitationAddress: "0x456b6e3f4B3Ba541824f97a4e525e277DC5176E3", //邀请地址
    PancakeV3Factory: "0x0BFbCF9fa4f9C56B0F40a671Ad40E0805A091865", //pancakeV3Factory工厂合约地址
    PancakeV3routerAddress:'0x1b81D678ffb9C0263b24A97847620C99d213eB14',//v3路由合约
    project_key: "vAny_vAny"
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
