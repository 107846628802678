<template>
  <div class="nav-muen">
    <div class="nav-muen-box">
      <div class="nav-muen-box-left">
        <div class="nav-muen-log"><img src="@/assets/logo.png" /></div>
        <div class="nav-muen-name"><span class="name-box">ANYSWAP</span></div>
      </div>
      <div
        class="nav-muen-box-right"
        @click="onMuenOpen"
        v-if="viewportWidth <= 481"
      >
        <img src="@/assets/menu.png" />
      </div>
      <div class="nav-muen-box-title" v-else>
        <!-- <img src="@/assets/menu.png" /> -->
        <div class="nav-item" @click="navigation('home')">
            {{ $t("navMuenDiv.nav_home") }}
          </div>
          <div class="nav-item" @click="navigation('myRulesView')">
            {{ $t("navMuenDiv.nav_rules") }}
          </div>
          <div class="nav-item" @click="navigation('swapConvert')">{{$t("swapDiv.swap_title")}}</div>
          <div class="nav-item" @click="selectLanguan('myRulesView')">{{ $t("navMuenDiv.nav_language") }}</div>
      </div>
    </div>

    <div class="nav-muen-list" @click="onMuenOpen" v-show="muenShow">
      <transition name="el-zoom-in-top">
        <div class="nav-muen-list-box" v-show="muenShow">
          <div class="nav-item" @click="navigation('home')">
            {{ $t("navMuenDiv.nav_home") }}
          </div>
          <div class="nav-item" @click="navigation('myRulesView')">
            {{ $t("navMuenDiv.nav_rules") }}
          </div>
          <div class="nav-item" @click="navigation('swapConvert')">Swap</div>
          <div class="nav-Language" @click="selectLanguan('myRulesView')">
            <div class="language-text">{{ $t("navMuenDiv.nav_language") }}</div>
            <div class="language-right">
              <div class="text1">{{ logOutUser }}</div>
              <div class="text2"><i class="el-icon-arrow-right"></i></div>
            </div>
          </div>
          <!-- <div class="nav-wallet" @click="navigation('myRulesView')">
            <div class="nav-wallet-left">
              <div class="wallet-logo"><img src="@/assets/metaMask.png" /></div>
              <div class="wallet-name">MetaMask</div>
            </div>
            <div class="nav-wallet-right" @click="onChange">Change</div>
            <div class="nav-wallet-right" @click="onlianjie">链接</div>
          </div> -->
        </div>
      </transition>
    </div>
    <my-language :isShow="enabledIsShow" @selectItem="selectItem"></my-language>
  </div>
</template>
<script>
import Web3 from "web3";
import MyLanguage from "@/components/language/language";
export default {
  components: {
    MyLanguage,
  },
  data() {
    return {
      muenShow: false,
      enabledIsShow: false,
      logOutUser: localStorage.getItem("languageName") || "English",
      viewportWidth: 0, //视口宽度
      viewportHeight: 0, //视口高度
    };
  },
  created() {
    //监听网络
    setTimeout(() => {
      this.onCheckChain();
    }, 500);
    this.updateViewportSize(); // 初始化获取视口尺寸
    window.addEventListener("resize", this.updateViewportSize);
  },
  //视口监听

  methods: {
    //切换语言
    selectLanguan() {
      this.enabledIsShow = !this.enabledIsShow;
    },
    updateViewportSize() {
      this.viewportWidth = window.innerWidth;
      this.viewportHeight = window.innerHeight;
    },
    //选中语言
    selectItem(item) {
      this.$i18n.locale = item.locale;
      localStorage.setItem("language", item.locale);

      localStorage.setItem("languageName", item.nameVal);
      // this.$ls.set('language',item.nameVal)
      this.logOutUser = item.nameVal;

      this.selectLanguan();
      window.location.reload();
    },
    onMuenOpen() {
      this.muenShow = !this.muenShow;
    },
    navigation(name) {
      if (this.$route.name == name) return;
      this.$router.push({ name });
      console.log(this.$route);
    },
    //取消连接
    async onChange() {
      // window.ethereum.send('eth_chainId', [], { method: 'eth_chainId' });
      // let web3 = new Web3(window.ethereum);
      // web3.eth.accounts.wallet.clear();
      // window.ethereum.disconnect()
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
      const newAccounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      console.log("取消连接", accounts, newAccounts);
    },
    onlianjie() {
      window.ethereum
        .enable()
        .then((res) => {
          console.log("进", res);
        })
        .catch((error) => {
          console.log("出", error);
          this.$message.error(error.message);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styleSass/variables.scss";
.nav-muen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
  // padding: 1.5rem;
  background: #040102;
  z-index: 1;
}
.nav-muen-box {
  width: 100%;
  display: flex;
  margin: 1.5rem 0;
  @include respond-to("phone") {
    justify-content: space-between;
  }

  .nav-muen-box-left {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5%;

    .nav-muen-log {
      height: 1.75rem;
      width: 1.75rem;
      margin-right: 0.5rem;
    }
    .nav-muen-log img {
      width: 100%;
      height: 100%;
    }
    .nav-muen-name {
      .name-box {
        color: #b2f050;
      }
    }
  }
  .nav-muen-box-right {
    margin-right: 5%;
  }
  .nav-muen-box-title {
    width: 100%;
    display: flex;
    .nav-item{
      width: 25%;
    }
  }
}
.nav-muen-list {
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 5%;
  z-index: 1;
  .nav-muen-list-box {
    background: #040102;

    .nav-item {
      width: 90%;
      text-align: left;
      height: 3rem;
      line-height: 3rem;
    }
    .nav-Language {
      display: flex;
      width: 90%;
      height: 3rem;
      justify-content: space-between;
      align-items: center;
      .language-text {
      }
      .language-right {
        display: flex;
        align-items: center;
        .text1 {
          margin-right: 0.2rem;
        }
        .text2 {
          transform: translateY(2px);
        }
      }
    }
    .nav-wallet {
      display: flex;
      width: 90%;
      height: 3rem;
      justify-content: space-between;
      align-items: center;

      .nav-wallet-left {
        width: 7.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .wallet-logo {
        }
        .wallet-name {
        }
      }
      .nav-wallet-right {
        // margin-right:5% ;
        // width: 20%;
      }
    }
  }
}
</style>
