<template>
  <div class="button-box" @click="submit" :style="`width:${width}height:${height}`">
    <div class="button" v-if="disable" style="background: #656565;">
      <span><slot></slot></span>
    </div>
    <div class="button" v-else :style="loadingItem?'background: #ffffff;':'background: #b4ef52;'">
      <span><slot></slot></span>
    </div>
   
  </div>
</template>
<script>
export default {
  props: {
    width:{
        typeof:String,
        default:'7.5rem;'
    },  
    height:{
        typeof:String,
        default:'2.125rem;'
    },  
    loading:{
        typeof:Boolean,
        default:false
    },
    disable:{
        typeof:Boolean,
        default:false
    }
  },
  watch: {
    loading(){
        this.loadingItem = this.loading;
    }
  },
  data() {
    return {
        loadingItem:this.loading,
    };
  },
  methods: {
    submit() {
        this.$emit('click');
    }
  },
};
</script>
<style lang="scss" scoped>
.button-box {
 
}
.button {
  color: black;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
