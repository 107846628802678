const en_ID = {
  homeDiv: {
    home_title: "Snapshot Untuk Menerima Airdrops",
    home_snapshot_coin: "Koin Snapshot",
    home_add_wallet: "Tambahkan ke dompet",
    home_capital: "Modal",
    home_participate: "Berpartisipasi",
    home_earnings: "Pendapatan",
    home_withdraw: "Tarik",
    home_aggregate_Earnings_Pool: "Pool Pendapatan Teragregasi",
    home_you_send: "Anda mengirim",
    home_you_get: "Anda menerima",
    home_buy_vANY: "Beli vANY",
  },
  homeJS: {
    home_snapshot: "Snapshot",
    home_incentives: "Insentif",
    home_all: "Semua",
    home_toward: "Menuju",
    home_stop: "Berhenti",
    home_my: "Saya",
    home_status: "status",
    home_token_added: "Token telah ditambahkan ke dompet.",
    home_token_addition: "Penambahan token gagal.",
    home_error_adding: "Kesalahan saat menambahkan token ke dompet.",
    home_error_adding_tokenYour:
      "Kesalahan menambahkan token. Dompet Anda mungkin tidak mendukungnya",
    home_please_install: "Silakan instal dompet",
    home_please_switch_networks: "Silakan ganti jaringan",
    home_network_error: "Kesalahan jaringan, ganti jaringan dan coba lagi",
    home_stake_loading: "Memuat stake",
    home_snapshot_balance:
      "saldo snapshot tidak cukup untuk mendapatkan reward, setidaknya:",
    home_you_are_blocked: "Anda diblokir",
    home_loading: "memuat...",
    home_account_error: "kesalahan akun",
    home_please_switch_chains: "Silakan ganti rantai",
    home_please_network: "Silakan pilih jaringan yang benar",
    home_please_address: "Silakan hubungkan alamat Anda",
    home_wallet_connect_deny: "koneksi dompet ditolak",
    home_old_wallet_version: "versi dompet lama",
    home_switch_chain_and_retry: "Ganti rantai dan coba lagi",
    home_enter_number_tokens: "Masukkan jumlah token",
    home_please_select_token: "Silakan pilih token",
    home_copy_success: "salin berhasil",
    home_copy_error: "kesalahan salin",
  },
  rulesDiv: {
    rules_title: "Aturan dan Alokasi Bunga",
    rules_text:
      "Dengan mengundang teman-teman Anda untuk masuk, jika teman-teman Anda memasukkan uang ke dalam penambangan, Anda akan diberi hadiah dengan bunga 10 persen dari teman-teman Anda, dan bunga ini tidak akan memengaruhi",
    rules_text2:
      "Investasikan uang untuk menjaminkan koin dan mendapatkan bunga, jika perubahan turun ke tingkat tertentu, platform akan melikuidasi token",
    rules_invite_friends: "Undang Teman",
    rules_me: "SAYA",
    rules_layer_users: "Pengguna Lapisan 1",
    rules_layer_users2: "Pengguna Lapisan 2",
    rules_layer_users3: "Pengguna Lapisan 3",
  },
  navMuenDiv: {
    nav_home: "Beranda",
    nav_rules: "Aturan",
    nav_language: "Bahasa",
  },
  cardDiv: {
    card_compound: "Compound V3",
    card_end_time: "Waktu Berakhir",
    card_APD: "APD",
    card_interest: "Bunga",
    card_commissions: "Komisi",
    card_capital: "Modal Terakumulasi",
    card_friends_interest: "Bunga Teman",
    card_supply: "Pasokan",
    card_Resupply: "Resupply",
    card_redem: "Tebus",
    card_wiuhdraw: "Tarik",
    card_enter_amount: "Masukkan jumlah",
    card_confirm: "konfirmasi",
    card_duration: "Durasi",
    card_days: "hari",
    card_minimum_withdrawal_amount: "Jumlah Penarikan Minimum",
    card_min_prarticion_amount: "Jumlah Partisipasi Minimum",
    card_current_Redeemable_Capital: "Modal yang Dapat Dikembalikan Saat Ini",
    card_Withdrawn_Commission: "Komisi yang Ditarik",
    card_Withdrawn_Interest: "Bunga yang Ditarik",
    card_Withdrawn_Principa: "Pokok yang Ditarik",
  },
  cardJS: {
    card_loading: "memuat",
    card_input_empty: "Jumlah input tidak boleh kosong",
    card_success: "Berhasil",
  },
  modeDiv: {
    mode_title: "Undang Teman",
    mode_save: "Simpan",
    mode_copy_success: "salin berhasil",
    mode_copy_error: "kesalahan salin",
    mode_redem: "Tebus",
    mode_confirm: "konfirmasi",
    mode_input_empty: "Jumlah input tidak boleh kosong",
    mode_loading: "Memuat...",
    mode_success: "berhasil",
    mode_failed: "gagal",
    mode_insufficient: "Dana tidak cukup untuk gas alam",
    mode_transaction: "Transaksi gagal",
    mode_insufficient_balance: "Saldo tidak mencukupi",
    mode_redeem_failed: "penebusan gagal",
    mode_rules: "Aturan",
    mode_inviting:
      "Dengan mengundang teman-teman Anda untuk masuk, jika teman-teman Anda memasukkan uang ke dalam penambangan, Anda akan diberi hadiah dengan bunga 10-30 persen",
    mode_supply: "Pasokan",
    mode_user_denied: "Pengguna menolak transaksi",
    mode_wiuhdraw: "Tarik",
    mode_: "Undang Teman",
    mode_wiuhdraw_text: "Tarik keuntungan atau komisi Anda",
    mode_supply_text: "Suplai prinsipal Anda",
    mode_redem_text: "Tukar kembali prinsipal Anda",
    mode_rules_text_1:
      "Dengan mengundang teman-teman Anda untuk masuk, jika teman-teman Anda memasukkan uang ke pertambangan, Anda akan mendapatkan bunga 10-30 persen.",
    mode_rules_text_2:
      "APD adalah pengembalian harian atas dana, yang akan sedikit berfluktuasi karena pengembalian akan ditentukan oleh penggunaan dana.",
    mode_rules_text_3:
      "Bunga atau komisi Anda dapat ditarik kapan saja saat Jumlah Penarikan Minimum tercapai.",
    mode_rules_text_4:
      "Dana yang Anda sediakan, setelah mencapai jangka waktu, dapat ditampilkan dalam Modal Tebusan Saat Ini, dan Anda dapat Menebus.",
    mode_rules_text_5:
      "Akhir proyek berarti: batas waktu proyek saat ini, akhir proyek tidak memengaruhi dana atau bunga Anda, penarikan komisi, akhir proyek, proyek baru akan dirilis, Anda dapat terlibat kembali dalam proyek baru.",
  },
  swapDiv: {
    swap_title: "Tukar",
    swap_trade: "Perdagangan token secara instan",
    swap_balance: "Saldo",
    swap_price: "Harga",
    swap_slippage: "Toleransi Slippage",
    swap_point: "Pilih titik geser",
    swap_SWAP: "TUKAR",
  },
  swapJS: {
    swap_quantity: "Silakan masukkan jumlah yang benar",
    swap_Successfullyswap: "Menukar berhasil.",
    swap_failed: "Menukar gagal.",
    swap_input_amount: "Jumlah input tidak boleh kosong.",
    swap_select_token: "Silakan pilih token.",
    swap_insufficient_balance: "Saldo Anda tidak mencukupi.",
  },
};
export default en_ID;
