//简体中文
const zh_FT = {
  homeDiv: {
    home_title: "快照以接收空投",
    home_snapshot_coin: "快照幣",
    home_add_wallet: "添加到錢包",
    home_capital: "資本",
    home_participate: "參與",
    home_earnings: "收益",
    home_withdraw: "提現",
    home_aggregate_Earnings_Pool: "總收益池",
    home_you_send: "您發送",
    home_you_get: "您接收",
    home_buy_vANY: "購買 vANY",
  },
  homeJS: {
    home_snapshot: "快照",
    home_incentives: "激勵",
    home_all: "全部",
    home_toward: "朝向",
    home_stop: "停止",
    home_my: "我的",
    home_status: "狀態",
    home_token_added: "代幣已經被添加到錢包中。",
    home_token_addition: "添加代幣失敗。",
    home_error_adding: "添加代幣到錢包時出錯。",
    home_error_adding_tokenYour: "添加 Token 時出錯。您的錢包可能不支持它",
    home_please_install: "請安裝錢包",
    home_please_switch_networks: "請切換網絡",
    home_network_error: "網絡錯誤，請切換網絡並重試",
    home_stake_loading: "Stake 加載中",
    home_snapshot_balance: "快照餘額不足以獲得獎勵，至少需要：",
    home_you_are_blocked: "您被封鎖了",
    home_loading: "加載中...",
    home_account_error: "賬戶錯誤",
    home_please_switch_chains: "請切換鏈",
    home_please_network: "請選擇正確的網絡",
    home_please_address: "請連接您的地址",
    home_wallet_connect_deny: "錢包連接被拒絕",
    home_old_wallet_version: "舊版本錢包",
    home_switch_chain_and_retry: "切換鏈並重試",
    home_enter_number_tokens: "輸入 Token 數量",
    home_please_select_token: "請選擇一個 Token",
    home_copy_success: "複製成功",
    home_copy_error: "複製錯誤",
  },
  rulesDiv: {
    rules_title: "規則及利息分配",
    rules_text:
      "通過邀請你的朋友加入，如果你的朋友將資金投入挖礦，你將獲得來自朋友的10-30%利息，這些利息將不會影響",
    rules_text2:
      "投資資金承諾硬幣並獲得利息，如果價格變動下降到一定水平，平台將清算代幣",
    rules_invite_friends: "邀請朋友",
    rules_me: "我",
    rules_layer_users: "第一層用戶",
    rules_layer_users2: "第二層用戶",
    rules_layer_users3: "第三層用戶",
  },
  navMuenDiv: {
    nav_home: "首頁",
    nav_rules: "規則",
    nav_language: "語言",
  },
  cardDiv: {
    card_compound: "Compound V3",
    card_end_time: "結束時間",
    card_APD: "APD",
    card_interest: "利息",
    card_commissions: "佣金",
    card_capital: "資本",
    card_friends_interest: "朋友利息",
    card_supply: "供應",
    card_Resupply: "重新供應",
    card_redem: "贖回",
    card_wiuhdraw: "提現",
    card_enter_amount: "輸入金額",
    card_confirm: "確認",
    card_duration: "持續時間",
    card_days: "天",
    card_min_prarticion_amount: "最低參與金額",
    card_minimum_withdrawal_amount: "最低提取金額",
    card_current_Redeemable_Capital: "當前可贖回資本",
    card_Withdrawn_Commission: "已提取佣金",
    card_Withdrawn_Interest: "已提取利息",
    card_Withdrawn_Principa: "已提取本金",
  },
  cardJS: {
    card_loading: "加載中",
    card_input_empty: "輸入金額不能為空",
    card_success: "成功",
  },
  modeDiv: {
    mode_title: "邀請朋友",
    mode_save: "保存",
    mode_copy_success: "複製成功",
    mode_copy_error: "複製錯誤",
    mode_redem: "贖回",
    mode_confirm: "確認",
    mode_input_empty: "輸入金額不能為空",
    mode_loading: "加載中...",
    mode_success: "成功",
    mode_failed: "失敗",
    mode_insufficient: "天然氣資金不足",
    mode_transaction: "交易失敗",
    mode_insufficient_balance: "餘額不足",
    mode_redeem_failed: "贖回失敗",
    mode_rules: "規則",
    mode_inviting:
      "通過邀請你的朋友加入，如果你的朋友將資金投入挖礦，你將獲得10%利息",
    mode_supply: "供應",
    mode_user_denied: "用戶拒絕了交易",
    mode_wiuhdraw: "提現",
    mode_: "邀請朋友",
    mode_wiuhdraw_text: "提取您的利息或佣金",
    mode_supply_text: "提供您的本金",
    mode_redem_text: "贖回您的本金",
    mode_rules_text_1:
      "通過邀請您的朋友參與，如果您的朋友將資金投入到挖礦中，您將獲得10-30%的利息獎勵",
    mode_rules_text_2:
      "APD是資金的日回報，由於回報將由資金利用確定，因此將稍微波動。",
    mode_rules_text_3: "您的利息或佣金可以在達到最低提取金額時隨時提取。",
    mode_rules_text_4:
      "您提供的資金，在達到期限後，可以顯示在當前可贖回資本中，您可以贖回。",
    mode_rules_text_5:
      "項目的結束意味著：當前項目的截止日期，項目的結束不會影響您的資金或利息，佣金提取，項目結束後，將發布新項目，您可以重新參與新項目。",
  },
  swapDiv: {
    swap_title: "交換",
    swap_trade: "即時交易代幣",
    swap_balance: "餘額",
    swap_price: "價格",
    swap_slippage: "滑點容忍度",
    swap_point: "選擇滑動點",
    swap_SWAP: "交換",
  },
  swapJS: { swap_quantity: "請輸入正確的數量" },
};
export default zh_FT;
