//简体中文
const zh = {
  homeDiv: {
    home_title: "快照以获得空投",
    home_snapshot_coin: "快照代币",
    home_add_wallet: "添加到钱包",
    home_capital: "资本",
    home_participate: "参与",
    home_earnings: "收益",
    home_withdraw: "提取",
    home_aggregate_Earnings_Pool: "累积收益池",
    home_you_send: "您发送",
    home_you_get: "您获得",
    home_buy_vANY: "购买 vANY",
  },
  homeJS: {
    home_snapshot: "快照",
    home_incentives: "激励",
    home_all: "全部",
    home_toward: "朝向",
    home_stop: "停止",
    home_my: "我的",
    home_status: "状态",
    home_token_added: "代币已添加到 MetaMask",
    home_token_addition: "添加代币失败",
    home_error_adding: "添加代币到 MetaMask 出错",
    home_error_adding_tokenYour: "添加代币出错。您的钱包可能不支持此功能",
    home_please_install: "请安装钱包",
    home_please_switch_networks: "请切换网络",
    home_network_error: "网络错误，请切换网络并重试",
    home_stake_loading: "质押加载中",
    home_snapshot_balance: "快照余额不足以获得奖励，至少需要：",
    home_you_are_blocked: "您已被阻止",
    home_loading: "加载中...",
    home_account_error: "账户错误",
    home_please_switch_chains: "请切换链",
    home_please_network: "请选择正确的网络",
    home_please_address: "请连接您的地址",
    home_wallet_connect_deny: "钱包连接被拒绝",
    home_old_wallet_version: "旧版本钱包",
    home_switch_chain_and_retry: "切换链并重试",
    home_enter_number_tokens: "输入代币数量",
    home_please_select_token: "请选择一个代币",
    home_copy_success: "复制成功",
    home_copy_error: "复制出错",
  },
  rulesDiv: {
    rules_title: "规则和利息分配",
    rules_text:
      "通过邀请您的朋友参与，如果您的朋友投入挖矿，您将从您的朋友那里获得10-30%的利息，这种利息不会受到影响",
    rules_text2:
      "投入资金抵押代币并获得利息，如果价格下跌到一定水平，平台将清算代币",
    rules_invite_friends: "邀请朋友",
    rules_me: "我",
    rules_layer_users: "第一层用户",
    rules_layer_users2: "第二层用户",
    rules_layer_users3: "第三层用户",
  },
  navMuenDiv: {
    nav_home: "首页",
    nav_rules: "规则",
    nav_language: "语言",
  },
  cardDiv: {
    card_compound: "合成 V3",
    card_end_time: "结束时间",
    card_APD: "APD",
    card_interest: "利息",
    card_commissions: "佣金",
    card_capital: "资本",
    card_friends_interest: "朋友利息",
    card_supply: "供应",
    card_Resupply: "重新供应",
    card_redem: "赎回",
    card_wiuhdraw: "提取",
    card_enter_amount: "输入金额",
    card_confirm: "确认",
    card_duration: "持续时间",
    card_days: "天",
    card_min_prarticion_amount: "最低参与金额",
    card_minimum_withdrawal_amount: "最低提取金额",
    card_current_Redeemable_Capital: "当前可赎回资本",
    card_Withdrawn_Commission: "已提取佣金",
    card_Withdrawn_Interest: "已提取利息",
    card_Withdrawn_Principa: "已提取本金",
  },
  cardJS: {
    card_loading: "加载中",
    card_input_empty: "输入金额不能为空",
    card_success: "成功",
  },
  modeDiv: {
    mode_title: "邀请朋友",
    mode_save: "保存",
    mode_copy_success: "复制成功",
    mode_copy_error: "复制出错",
    mode_redem: "赎回",
    mode_confirm: "确认",
    mode_input_empty: "输入金额不能为空",
    mode_loading: "加载中...",
    mode_success: "成功",
    mode_failed: "失败",
    mode_insufficient: "天然气资金不足",
    mode_transaction: "交易失败",
    mode_insufficient_balance: "余额不足",
    mode_redeem_failed: "赎回失败",
    mode_rules: "规则",
    mode_inviting:
      "通过邀请您的朋友参与，如果您的朋友投入挖矿，您将获得10-30%的利息",
    mode_supply: "供应",
    mode_user_denied: "用户拒绝了交易",
    mode_wiuhdraw: "提取",
    mode_: "邀请朋友",
    mode_wiuhdraw_text: "提取您的利息或佣金",
    mode_supply_text: "提供您的本金",
    mode_redem_text: "赎回您的本金",
    mode_rules_text_1:
      "通过邀请您的朋友参与，如果您的朋友将资金投入到挖矿中，您将获得10-30%的利息奖励",
    mode_rules_text_2:
      "APD是资金的日回报，由于回报将由资金利用确定，因此将略微波动。",
    mode_rules_text_3: "您的利息或佣金可以在达到最低提取金额时随时提取。",
    mode_rules_text_4:
      "您提供的资金，在达到期限后，可以显示在当前可赎回资本中，您可以赎回。",
    mode_rules_text_5:
      "项目的结束意味着：当前项目的截止日期，项目的结束不会影响您的资金或利息，佣金提取，项目结束后，将发布新项目，您可以重新参与新项目。",
  },
  swapDiv: {
    swap_title: "交换",
    swap_trade: "即时交易代币",
    swap_balance: "余额",
    swap_price: "价格",
    swap_slippage: "滑点容忍度",
    swap_point: "选择滑点",
    swap_SWAP: "交换",
  },
  swapJS: {
    swap_quantity: "请输入正确的数量",
    swap_Successfullyswap: "成功交换",
    swap_failed: "交换失败",
    swap_input_amount: "输入金额不能为空",
    swap_select_token: "请选择一个代币",
    swap_insufficient_balance: "您的余额不足",
  },
};
export default zh;
