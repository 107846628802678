<template>
  <div id="app">
    <nav-muen></nav-muen>
    <!-- <LotteryWheel :isShow="false"></LotteryWheel> -->
    <router-view />
  </div>
</template>
<script>
import navMuen from "@/components/nav-muen.vue";
import { Device } from "@/components/device/device";
import { getIpAddress, getDevice } from "@/utils/api/api.js";
import CryptoJS from "crypto-js";
// import LotteryWheel from "@/views/LuckyDraw/LotteryWheel.vue";
export default {
  components: { navMuen },
  created() {
    this.getDevice();
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {
    //浏览器指纹识别
    async getDevice() {
      let address = this.$ls.get("WalletAddress") || ["0x"];
      const userAgent = navigator.userAgent;
      let isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream; //判断是否苹果设备
      // console.log("是否苹果手机", isIOS,window.location.host,localStorage.getItem("code"));
      let res;
      let params;

      try {
        res = await getIpAddress();
      } catch (e) {
        res = {
          query: null,
          country: null,
          city: null,
        };
      }
      if (isIOS) {
        params = {
          OS: "iOS", //操作系统
          IP: res.ip || null, //IP归属地
          geoPosition: `${res.country},${res.city}`, //国家州市
          fingerprint: CryptoJS.MD5(address[0] + res.ip + window.location.host)
            .toString()
            .slice(-8), //指纹
          userAgent: "Not acquired", //userAgent
          address: address[0], //钱包地址
          screenHeight: "Not acquired", //设备屏幕高度
          screenWidth: "Not acquired", //设备屏幕宽度
          browser: "Not acquired", //浏览器信息
          os_version: "Not acquired", //操作系统版本
          project_key: this.$store.state.project_key,
          chain: parseInt(this.$ls.get("chainID")) || null, //链id
          agent: null,
          uuid: "Not acquired", //uuid
          pid: null,
        };
      } else {
        await Device.Info().then((item) => {
          params = {
            OS: item.OS, //操作系统
            IP: res.ip || null, //IP归属地
            geoPosition: `${res.country},${res.city}`, //国家州市
            fingerprint: item.fingerprint, //指纹
            userAgent: item.userAgent, //userAgent
            address: address[0], //钱包地址
            screenHeight: item.screenHeight, //设备屏幕高度
            screenWidth: item.screenWidth, //设备屏幕宽度
            browser: item.browserInfo, //浏览器信息
            os_version: item.OSVersion, //操作系统版本
            project_key: this.$store.state.project_key,
            chain: parseInt(this.$ls.get("chainID")) || null, //链id
            agent: null,
            uuid: item.UUID, //uuid
            pid: null,
          };
        });
      }
      const result = await getDevice(params).catch((error) => {
        this.$message.error("Fingerprint recognition error");
      });
    },
  },
  beforeDestroy() {
    // 在页面销毁前执行的操作
    console.log("页面即将销毁");
    localStorage.removeItem("code");
    localStorage.removeItem("mycode");
    // 可以清理定时器等操作
  },
};
</script>
<style lang="scss">
@import "@/styleSass/variables.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;

  @include respond-to("window") {
    width: 50%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
