const en_BS = {
  homeDiv: {
    home_title: "عکس فوری برای دریافت ایردراپ‌ها",
    home_snapshot_coin: "سکه عکس فوری",
    home_add_wallet: "اضافه کردن به کیف پول",
    home_capital: "سرمایه",
    home_participate: "شرکت کردن",
    home_earnings: "درآمد",
    home_withdraw: "برداشت",
    home_aggregate_Earnings_Pool: "مجموع سودهای جمع شده",
    home_you_send: "شما ارسال می‌کنید",
    home_you_get: "شما دریافت می‌کنید",
    home_buy_vANY: "خرید vANY",
  },
  homeJS: {
    home_snapshot: "عکس فوری",
    home_incentives: "مشوق‌ها",
    home_all: "همه",
    home_toward: "به سمت",
    home_stop: "توقف",
    home_my: "من",
    home_status: "وضعیت",
    home_token_added: 'توکن به کیف پول اضافه شد.',
    home_token_addition: 'افزودن توکن شکست خورد.',
    home_error_adding: 'خطا در هنگام افزودن توکن به کیف پول.',
    home_error_adding_tokenYour:
      "خطا در اضافه کردن توکن. ممکن است کیف پول شما از آن پشتیبانی نکند",
    home_please_install: "لطفاً کیف پول را نصب کنید",
    home_please_switch_networks: "لطفاً شبکه را تغییر دهید",
    home_network_error: "خطای شبکه، شبکه را تغییر دهید و دوباره تلاش کنید",
    home_stake_loading: "بارگذاری سهام",
    home_snapshot_balance:
      "موجودی عکس فوری برای دریافت پاداش کافی نیست، حداقل:",
    home_you_are_blocked: "شما مسدود شده‌اید",
    home_loading: "در حال بارگذاری...",
    home_account_error: "خطای حساب",
    home_please_switch_chains: "لطفاً زنجیره‌ها را تغییر دهید",
    home_please_network: "لطفاً شبکه صحیح را انتخاب کنید",
    home_please_address: "لطفاً آدرس خود را متصل کنید",
    home_wallet_connect_deny: "اتصال کیف پول رد شد",
    home_old_wallet_version: "نسخه قدیمی کیف پول",
    home_switch_chain_and_retry: "زنجیره را تغییر دهید و دوباره تلاش کنید",
    home_enter_number_tokens: "تعداد توکن‌ها را وارد کنید",
    home_please_select_token: "لطفاً یک توکن را انتخاب کنید",
    home_copy_success: "کپی موفقیت‌آمیز بود",
    home_copy_error: "خطای کپی",
  },
  rulesDiv: {
    rules_title: "قوانین و تخصیص سود",
    rules_text:
      "با دعوت دوستان خود به ورود، اگر دوستان شما پول را به استخراج وارد کنند، شما با 10 درصد از سود دوستان خود پاداش خواهید گرفت و این سود تاثیری نخواهد داشت",
    rules_text2:
      "سرمایه‌گذاری پول برای تعهد سکه‌ها و دریافت سود، اگر تغییر به سطح معینی برسد، پلتفرم توکن‌ها را تسویه خواهد کرد",
    rules_invite_friends: "دعوت دوستان",
    rules_me: "من",
    rules_layer_users: "کاربران لایه 1",
    rules_layer_users2: "کاربران لایه 2",
    rules_layer_users3: "کاربران لایه 3",
  },
  navMuenDiv: {
    nav_home: "خانه",
    nav_rules: "قوانین",
    nav_language: "زبان",
  },
  cardDiv: {
    card_compound: "Compound V3",
    card_end_time: "زمان پایان",
    card_APD: "APD",
    card_interest: "سود",
    card_commissions: "کمیسیون‌ها",
    card_capital: "جمع‌آوری سرمایه",
    card_friends_interest: "سود دوستان",
    card_supply: "عرضه",
    card_Resupply:'Resupply',
    card_redem: "بازخرید",
    card_wiuhdraw: "برداشت",
    card_enter_amount: "مقدار را وارد کنید",
    card_confirm: "تایید",
    card_duration: "مدت",
    card_days: "روز",
    card_minimum_withdrawal_amount: "حداقل مبلغ برداشت",
    card_min_prarticion_amount: "حداقل مبلغ شرکت",
    card_current_Redeemable_Capital: "سرمایه قابل بازپس‌گرفتن فعلی",
    card_Withdrawn_Commission: "کمیسیون بازپس‌گرفته شده",
    card_Withdrawn_Interest: "سود بازپس‌گرفته شده",
    card_Withdrawn_Principa: "اصل بازپس‌گرفته شده",
  },
  cardJS: {
    card_loading: "در حال بارگذاری",
    card_input_empty: "مقدار ورودی نمی‌تواند خالی باشد",
    card_success: "موفقیت",
  },
  modeDiv: {
    mode_title: "دعوت دوستان",
    mode_save: "ذخیره",
    mode_copy_success: "کپی موفقیت‌آمیز بود",
    mode_copy_error: "خطای کپی",
    mode_redem: "بازخرید",
    mode_confirm: "تایید",
    mode_input_empty: "مقدار ورودی نمی‌تواند خالی باشد",
    mode_loading: "در حال بارگذاری...",
    mode_success: "موفقیت",
    mode_failed: "ناموفق",
    mode_insufficient: "بودجه ناکافی برای گاز طبیعی",
    mode_transaction: "تراکنش ناموفق بود",
    mode_insufficient_balance: "موجودی ناکافی",
    mode_redeem_failed: "بازخرید ناموفق بود",
    mode_rules: "قوانین",
    mode_inviting:
      "با دعوت دوستان خود به ورود، اگر دوستان شما پول را به استخراج وارد کنند، شما با 10-30 درصد از سود پاداش خواهید گرفت",
    mode_supply: "عرضه",
    mode_user_denied: "کاربر تراکنش را رد کرد",
    mode_wiuhdraw: "برداشت",
    mode_: "دعوت دوستان",
    mode_wiuhdraw_text: "سود یا کمیسیون خود را بردارید",
    mode_supply_text: "سرمایه خود را تأمین کنید",
    mode_redem_text: "سرمایه خود را واگذار کنید",
    mode_rules_text_1: "با دعوت دوستان خود برای ورود، اگر دوستان شما پول را در معدن‌کاری قرار دهند، شما با10- 30 درصد سود پاداش داده خواهید شد.",
    mode_rules_text_2: "APD بازگشت روزانه بر روی موارد است، که کمی به عنوان بازگشت تعیین خواهد شد وابسته به استفاده از موارد کمی نوسان خواهد کرد.",
    mode_rules_text_3: "سود یا کمیسیون شما هنگام رسیدن به حداقل مبلغ برداشت می‌تواند در هر زمان برداشت شود.",
    mode_rules_text_4: "سرمایه‌گذاری شده توسط شما، پس از رسیدن به مدت، می‌تواند در سرمایه قابل تبدیل کنونی نمایش داده شود و شما می‌توانید آن را تبدیل کنید.",
    mode_rules_text_5: "پایان پروژه به معنای: مهلت پروژه فعلی، پایان پروژه بر روی سرمایه یا سود شما، برداشت کمیسیون، پایان پروژه، یک پروژه جدید منتشر خواهد شد، شما می‌توانید در یک پروژه جدید شرکت کنید."
  },
  swapDiv: {
    swap_title: "تعویض",
    swap_trade: "معامله توکن ها به صورت فوری",
    swap_balance: "موجودی",
    swap_price: "قیمت",
    swap_slippage: "تحمل لغزش",
    swap_point: "نقطه لغزش را انتخاب کنید",
    swap_SWAP: "تعویض",
  },
  swapJS: {
    swap_quantity: "لطفا مقدار صحیح را وارد کنید",
    swap_Successfullyswap: 'تبادل موفقیت‌آمیز.',
    swap_failed: 'تبادل شکست خورد.',
    swap_input_amount: 'مقدار ورودی نمی‌تواند خالی باشد.',
    swap_select_token: 'لطفاً یک توکن را انتخاب کنید.',
    swap_insufficient_balance: 'موجودی شما کافی نیست.'
  },
};
export default en_BS;
