import Vue from "vue";
import VueClipboard from "vue-clipboard2";
Vue.directive("format", {
  bind(el, binding) {
    el.innerHTML = formatNumber(binding.value);
  },
  update(el, binding) {
    el.innerHTML = formatNumber(binding.value);
  },
});
function formatNumber(number) {
  // 使用正则表达式将数字格式化为千分位
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
Vue.directive("clipboard", VueClipboard);
