import Vue from'vue'
import VueI18n from 'vue-i18n';
import zh from './comom/zh'
import zh_FT from './comom/zh_FT' //繁体中文
import en from './comom/en' //英文
import ja_JP from './comom/ja_JP' //日语
import ko_KR from './comom/ko_KR' //  韩语
import en_TH from './comom/en_TH' //泰语
import fr_FR from './comom/fr_FR' //法语
import ru_RU from './comom/ru_RU' //俄语
import en_IN from './comom/en_IN' //印度
import en_ID from './comom/en_ID' //印度尼西亚
import en_BS from './comom/en_BS' //波斯
import en_YN from './comom/en_YN' //越南
import en_ALB from './comom/en_ALB' //阿拉伯
import en_TEQ from './comom/en_TEQ' //土耳其
import en_ES from './comom/en_ES' //西班牙
import en_PTY from './comom/en_PTY' //葡萄牙
import en_YDL from './comom/en_YDL' //意大利语
Vue.use(VueI18n);
// let locale=;
console.log('ls打印',localStorage.getItem('language'))
const i18n = new VueI18n({
    locale:localStorage.getItem('language') || 'en', // 默认语言
    fallbackLocale: 'en', // 当当前语言缺失翻译时使用的备用语言
    messages: {
        zh_FT,
        zh,
        en,
        ja_JP,
        ko_KR,
        en_TH,
        fr_FR,
        ru_RU,
        en_IN,
        en_ID,
        en_BS,
        en_YN,
        en_ALB,
        en_TEQ,
        en_ES,
        en_PTY,
        en_YDL
    }
});
export default i18n