const en_ALB = {
  homeDiv: {
    home_title: "لقطة لتلقي الإيردروب",
    home_snapshot_coin: "عملة اللقطة",
    home_add_wallet: "أضف إلى المحفظة",
    home_capital: "رأس المال",
    home_participate: "شارك",
    home_earnings: "الأرباح",
    home_withdraw: "سحب",
    home_aggregate_Earnings_Pool: "مجمع الأرباح الإجمالي",
    home_you_send: "أنت ترسل",
    home_you_get: "أنت تحصل",
    home_buy_vANY: "شراء vANY",
  },
  homeJS: {
    home_snapshot: "لقطة",
    home_incentives: "الحوافز",
    home_all: "الكل",
    home_toward: "نحو",
    home_stop: "توقف",
    home_my: "خاصتي",
    home_status: "الحالة",
    home_token_added: "تم إضافة الرمز إلى المحفظة.",
    home_token_addition: "فشل إضافة الرمز.",
    home_error_adding: "خطأ في إضافة الرمز إلى المحفظة.",
    home_error_adding_tokenYour: "خطأ في إضافة الرمز. قد لا تدعم محفظتك ذلك",
    home_please_install: "يرجى تثبيت المحفظة",
    home_please_switch_networks: "يرجى تغيير الشبكات",
    home_network_error: "خطأ في الشبكة، قم بتغيير الشبكة وحاول مرة أخرى",
    home_stake_loading: "تحميل الرهان",
    home_snapshot_balance:
      "رصيد اللقطة غير كافٍ للحصول على المكافأة، على الأقل:",
    home_you_are_blocked: "أنت محظور",
    home_loading: "جارٍ التحميل...",
    home_account_error: "خطأ في الحساب",
    home_please_switch_chains: "يرجى تغيير السلاسل",
    home_please_network: "يرجى اختيار الشبكة الصحيحة",
    home_please_address: "يرجى توصيل عنوانك",
    home_wallet_connect_deny: "رفض اتصال المحفظة",
    home_old_wallet_version: "إصدار المحفظة القديم",
    home_switch_chain_and_retry: "غير السلسلة وجرب مرة أخرى",
    home_enter_number_tokens: "أدخل عدد الرموز",
    home_please_select_token: "يرجى اختيار رمز",
    home_copy_success: "نسخ ناجح",
    home_copy_error: "خطأ في النسخ",
  },
  rulesDiv: {
    rules_title: "القواعد وتخصيص الفائدة",
    rules_text:
      "من خلال دعوة أصدقائك للدخول، إذا وضع أصدقاؤك المال في التعدين، فستتم مكافأتك بنسبة 10 بالمائة من فائدة أصدقائك، وهذه الفائدة لن تؤثر",
    rules_text2:
      "استثمر المال لتعهيد العملات والحصول على الفائدة، إذا انخفض التغيير إلى مستوى معين، ستقوم المنصة بتصفية الرموز",
    rules_invite_friends: "ادعُ الأصدقاء",
    rules_me: "أنا",
    rules_layer_users: "مستخدمو الطبقة 1",
    rules_layer_users2: "مستخدمو الطبقة 2",
    rules_layer_users3: "مستخدمو الطبقة 3",
  },
  navMuenDiv: {
    nav_home: "الرئيسية",
    nav_rules: "القواعد",
    nav_language: "اللغة",
  },
  cardDiv: {
    card_compound: "Compound V3",
    card_end_time: "وقت الانتهاء",
    card_APD: "APD",
    card_interest: "الفائدة",
    card_commissions: "العمولات",
    card_capital: "راس المال المتراكم",
    card_friends_interest: "فائدة الأصدقاء",
    card_supply: "التوريد",
    card_Resupply: "Resupply",
    card_redem: "استرداد",
    card_wiuhdraw: "سحب",
    card_enter_amount: "أدخل المبلغ",
    card_confirm: "تأكيد",
    card_duration: "المدة الزمنية",
    card_days: "أيام",
    card_minimum_withdrawal_amount: "الحد الأدنى للسحب",
    card_min_prarticion_amount: "الحد الأدنى للمشاركة",
    card_current_Redeemable_Capital: "رأس المال القابل للاسترداد الحالي",
    card_Withdrawn_Commission: "العمولة المسحوبة",
    card_Withdrawn_Interest: "الفائدة المسحوبة",
    card_Withdrawn_Principa: "الرأسمال المسحوب",
  },
  cardJS: {
    card_loading: "جارٍ التحميل",
    card_input_empty: "لا يمكن أن يكون المبلغ المُدخل فارغًا",
    card_success: "نجاح",
  },
  modeDiv: {
    mode_title: "ادعُ الأصدقاء",
    mode_save: "حفظ",
    mode_copy_success: "نسخ ناجح",
    mode_copy_error: "خطأ في النسخ",
    mode_redem: "استرداد",
    mode_confirm: "تأكيد",
    mode_input_empty: "لا يمكن أن يكون المبلغ المُدخل فارغًا",
    mode_loading: "جارٍ التحميل...",
    mode_success: "نجاح",
    mode_failed: "فشل",
    mode_insufficient: "تمويل غير كافٍ للغاز الطبيعي",
    mode_transaction: "فشل المعاملة",
    mode_insufficient_balance: "رصيد غير كافٍ",
    mode_redeem_failed: "فشل الاسترداد",
    mode_rules: "القواعد",
    mode_inviting:
      "من خلال دعوة أصدقائك للدخول، إذا وضع أصدقاؤك المال في التعدين، فستتم مكافأتك بنسبة 10-30 بالمائة من الفائدة",
    mode_supply: "التوريد",
    mode_user_denied: "رفض المستخدم المعاملة",
    mode_wiuhdraw: "سحب",
    mode_: "ادعُ الأصدقاء",
    mode_wiuhdraw_text: "سحب فائدتك أو عمولتك",
    mode_supply_text: "توفير رأس مالك",
    mode_redem_text: "استرداد رأس مالك",
    mode_rules_text_1:
      "من خلال دعوة أصدقائك للدخول، إذا قام أصدقاؤك بوضع الأموال في التعدين، ستحصل على ا10- 30 في المئة فائدة",
    mode_rules_text_2:
      "APD هو العائد اليومي على الأموال، الذي سيتقلب قليلاً حيث يتم تحديد العائد عن طريق استخدام الأموال.",
    mode_rules_text_3:
      "يمكن سحب فائدتك أو عمولتك في أي وقت عندما يتم الوصول إلى الحد الأدنى لسحب المبلغ.",
    mode_rules_text_4:
      "الأموال التي تقدمها، بعد تحقيق المدة، يمكن عرضها في رأس المال القابل للتحويل الحالي، ويمكنك تحويلها.",
    mode_rules_text_5:
      "نهاية المشروع تعني: الموعد النهائي للمشروع الحالي، نهاية المشروع لا تؤثر على أموالك أو فائدتك، سحب العمولة، نهاية المشروع، سيتم إصدار مشروع جديد، يمكنك المشاركة مرة أخرى في مشروع جديد.",
  },
  swapDiv: {
    swap_title: "التبادل",
    swap_trade: "تداول الرموز بشكل فوري",
    swap_balance: "الرصيد",
    swap_price: "السعر",
    swap_slippage: "تحمل الانزلاق",
    swap_point: "اختر نقطة الانزلاق",
    swap_SWAP: "تبادل",
  },
  swapJS: {
    swap_quantity: "الرجاء إدخال الكمية الصحيحة",
    swap_Successfullyswap: "تم تبادله بنجاح.",
    swap_failed: "فشل التبادل.",
    swap_input_amount: "لا يمكن أن يكون المبلغ الداخلي فارغًا.",
    swap_select_token: "الرجاء اختيار رمز.",
    swap_insufficient_balance: "رصيدك غير كافٍ.",
  },
};
export default en_ALB;
