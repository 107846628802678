<template>
  <transition name="slide-right">
    <div class="language-page" v-show="isShow">
      <div class="language-content">
        <div class="head-title">language</div>
        <div class="language-list">
          <div
            class="language-item"
            v-for="(item, idx) in languageData"
            :key="idx"
            @click="calculateArea(item, idx)"
          >
            <div class="left">
              {{ item.nameVal }}
            </div>
            <div class="right" v-if="activeIdx == idx">
              <b><i class="el-icon-check"></i></b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
    props:{
        isShow:{
            typeof:Boolean
        },
    },
  data() {
    return {
      languageData: [
        {
          key: 0,
          nameVal: "中文简体",
          locale: "zh",
        },
        {
          key: 1,
          nameVal: "中文繁體",
          locale: "zh_FT",
        },
        {
          key: 2,
          nameVal: "English",
          locale: "en",
        },

        {
          key: 3,
          nameVal: "한국어",
          locale: "ko_KR",
        },
        {
          key: 4,
          nameVal: "ภาษาไทย",
          locale: "en_TH",
        },
        {
          key: 5,
          nameVal: "Français",
          locale: "fr_FR",
        },
        {
          key: 6,
          nameVal: "Русский язык",
          locale: "ru_RU",
        },
        {
          key: 7,
          nameVal: "हिन्दी",
          locale: "en_IN",
        },
        {
          key: 8,
          nameVal: "Bahasa indonesia",
          locale: "en_ID",
        },
        {
          key: 9,
          nameVal: "فارسی",
          locale: "en_BS",
        },
        {
          key: 10,
          nameVal: "Tiếng Việt",
          locale: "en_YN",
        },
        {
          key: 11,
          nameVal: "بالعربية",
          locale: "en_ALB",
        },
        {
          key: 12,
          nameVal: "Türkçe",
          locale: "en_TEQ",
        },
        {
          key: 13,
          nameVal: "Español",
          locale: "en_ES",
        },
        {
          key: 14,
          nameVal: "Português",
          locale: "en_PTY",
        },
        {
          key: 15,
          nameVal: "Italiano",
          locale: "en_YDL",
        },
        {
          key: 16,
          nameVal: "日本語",
          locale: "ja_JP",
        },
      ],
      activeIdx: 0,
    };
  },
  created(){
    this.defaultIdx()
  },
  methods: {
    defaultIdx(){
        if(localStorage.getItem('language')){
            this.languageData.forEach((item, index) => {
                if(item.locale == localStorage.getItem('language')){
                    this.activeIdx=item.key
                }
            })
            
        }
    },
    calculateArea(item, index) {
      this.activeIdx = index;
      this.$emit("selectItem", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.language-page {
  position: fixed;
  top: 3rem;
  left: 0;
  width: 90%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  padding: 5%;
}
.language-content {
  height: 100%;
  width: 100%;

  .head-title {
    font-weight: 600;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    text-align: left;
    height: 4rem;
    border-bottom: 1px solid #4c4c4c;
  }
  .language-list {
    max-height: 80%;
      overflow: auto;
    .language-item {
      height: 3rem;
      display: flex;
     
      align-items: center;
      justify-content: space-between;
      .left {
      }
      .right {
      }
    }
  }
}
fade-enter-active,
.slide-right-enter-active {
  transition: transform 0.5s;
}

.slide-right-enter {
  transform: translateX(100%);
}

.slide-right-leave-active {
  transition: transform 0.5s;
}

.slide-right-leave-to {
  transform: translateX(100%);
}
</style>
